@import '/theme';

.DataTableCalendarEvent {
  border-radius: 5px;
  overflow: hidden;

  span {
    padding: 0.5em;

    &.DataTableCalendarEventLabel {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 5px;
    }
  }
}

.rbc-day-slot {
  .rbc-event {
    padding: 2px 5px;
    margin: 0;
  }
  .DataTableCalendarEvent {
    span {
      padding: 0 0.25em 0 0;
      &.DataTableCalendarEventLabel {
        background: none;
      }
    }
  }
}

.rbc-overlay {
  border: 1px solid $primary_colour;
  border-radius: 5px;
  box-shadow: none;
  padding: 1em;

  .rbc-overlay-header {
    font-weight: bold;
    padding: 0.5em 1em;
    margin-bottom: 1em;
  }

  .rbc-event {
    margin-bottom: 0.5em;
    padding: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.rbc-event {
  border-radius: 5px;
  font-weight: bold;
  padding: 0;
  margin: 2px 0;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }

  &.grey {
    background-color: $midGrey;
  }

  &.blue {
    background-color: $blue;
  }

  &.yellow {
    background-color: $yellow;
  }

  &.orange {
    background-color: $orange;
  }

  &.red {
    background-color: $red;
  }
  &.green {
    background-color: $green;
  }
}

.DataTableContainer {
  overflow-y: auto;

  .DataTableHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .DataTableTitleContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-grow: 2;

      svg {
        color: $tertiary_colour;
        font-size: 26px;
        margin-right: 15px;
      }

      h1 {
        color: $tertiary_colour;
        font-size: 24px;
        margin: 0;
      }
    }
  }

  .DataTableSummaryContainer {
    height: 100%;
    padding: 0 1em;

    .SummaryStatRow {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-between;
      margin-bottom: 20px;

      .SummaryStat {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: $primary_colour;
        height: 100px;
        border-radius: 5px;
        padding: 10px;
        color: $white;
        text-align: right;
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;
        }

        p:first-of-type {
          font-weight: bold;
          font-size: 1.5em;
          margin-bottom: 0;
        }

        p:last-of-type {
          font-weight: bold;
          font-size: 2.25em;
        }
      }

      .SummaryGraph {
        text-align: center;
        flex-grow: 2;
        margin-right: 50px;

        &:last-of-type {
          margin-right: 0;
        }

        > p {
          font-weight: bold;
          font-size: 1.5em;
        }

        > svg {
          width: 80%;
          max-width: 350px;
          overflow: visible;
        }
      }
    }

    .RatingStatRow {
      max-width: 800px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      text-align: center;
      margin-top: 70px;
      padding-bottom: 40px;

      > p {
        font-size: 1.5em;
        font-weight: bold;
      }

      > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: center;

        > .StarRatingContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            height: 38px;
            font-size: 1.5em;
          }

          .yellow {
            color: $yellow;
          }
          .grey {
            color: $midGrey;
          }

          p {
            font-weight: bold;
            font-size: 1.25em;
          }
        }
      }
    }
  }

  .DataTableHeader {
    font-weight: bold;
  }

  .DataTableDescription {
    margin-bottom: 20px;
  }

  .DataTableCalendarSwitch {
    label {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      color: $tertiary_colour;

      svg {
        font-size: 1.25em;
        margin-right: 10px;
      }

      span {
        margin-right: 10px;
        font-weight: bold;
      }
    }
  }

  .DataTableCalendarContainer {
    height: 100%;
    min-height: 600px;
    padding: 1em;
    overflow: hidden;

    .rbc-toolbar {
      font-weight: bold;
      margin-bottom: 1em;

      button {
        font-family: 'Lato', 'Nunito Sans', -apple-system, BlinkMacSystemFont,
          'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
          'Droid Sans', 'Helvetica Neue', sans-serif !important;
        font-weight: bold;
        font-size: 14px;
        padding: 0.5em 1.5em;

        background: $white;
        color: $offBlack;
        box-shadow: none !important;
        border-color: $darkGrey !important;

        &:hover {
          background-color: $primary_colour;
          color: $white;
          cursor: pointer;
        }

        &:focus {
          cursor: pointer;
          outline: none;
        }

        &.rbc-active {
          background-color: $secondary_colour;
          color: $white;
        }
      }
    }

    .rbc-month-view {
      border-radius: 5px;
    }

    .grey {
      background-color: $midGrey;
    }

    .blue {
      background-color: $blue;
    }

    .yellow {
      background-color: $yellow;
    }

    .orange {
      background-color: $orange;
    }

    .red {
      background-color: $red;
    }
    .green {
      background-color: $green;
    }
  }

  @media (max-width: 768px) {
    .hideMob {
      display: none;
    }
  }

  table {
    text-align: center !important;

    tbody {
      tr {
        td {
          .DataTableImageContainer {
            position: relative;
            width: 100px;
            height: 70px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin: 0 auto;
            border-radius: 5px;
            overflow: hidden;

            a {
              position: absolute;
              top: 10px;
              right: 10px;

              background: $secondary_colour;
              padding: 6px 10px;
              border-radius: 5px;
              color: $white;

              &:hover {
                background: $primary_colour;
              }
            }
          }

          .DataTableDownload {
            background: $secondary_colour;
            border-radius: 5px;

            &:hover {
              background: $primary_colour;
            }

            a {
              display: block;
              padding: 10px;
              color: $white;
            }
          }
        }
      }
    }
  }
}

.StyledDataTable {
  h1 {
    color: $tertiary_colour;
    margin-bottom: 0;

    span {
      color: $midGrey;
      font-size: 16px;
    }
  }

  table {
    background: none !important;
    border: none !important;
    border-collapse: separate !important;
    border-spacing: 0 0.9em !important;
    text-align: center !important;
    margin-top: 0 !important;

    thead th {
      background: none !important;
      border: none !important;
      color: $midGrey !important;
      text-align: center !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    tbody tr {
      background: $white !important;
      box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.1) !important;
      border-radius: 5px;

      &:hover {
        background: $secondary_colour !important;
        color: $white !important;
        font-weight: bold;
        cursor: pointer;
      }

      td {
        min-height: 73px !important;
        border: none !important;
        margin-bottom: 10px !important;
        border-top: 1px solid $darkGrey !important;
        border-bottom: 1px solid $darkGrey !important;
        padding: 20px 10px 18px !important;

        &:first-of-type {
          font-weight: bold;
          border-radius: 5px 0 0 5px;
          border-left: 1px solid $darkGrey !important;
        }

        &:last-of-type {
          border-radius: 0 5px 5px 0;
          border-right: 1px solid $darkGrey !important;
        }
      }

      &.NoData {
        background: none !important;
        border: none !important;
        box-shadow: none !important;
        color: black !important;
        cursor: auto;

        td {
          border: none !important;
        }
      }
    }

    tfoot {
      tr {
        th {
          background: none !important;
          border: none !important;
          padding: 10px 0 0 0 !important;
        }
      }
    }
  }
}

.DataTablePagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1em;

  .DataTablePaginationTotals {
    color: $midGrey;
    margin-right: 1em;
  }

  .DataTablePaginationButtons {
    display: flex;
    flex-grow: 2;
    justify-content: flex-end;
  }

  .pagination {
    .item {
      font-weight: bold !important;

      &:hover {
        background-color: $secondary_colour !important;
        color: $white !important;
      }
    }

    .active {
      background-color: $primary_colour !important;
      color: $white !important;
    }
  }

  .DataTablePaginationSort {
    .ui.selection.dropdown {
      border-color: $primary_colour !important;
    }
  }
}

.GreyCell {
  background-color: $midGrey;
  color: $white;
  padding: 7px 0;
  border-radius: 20px;
  font-weight: bold;
  min-width: 100px;
}

.BlueCell {
  background-color: $blue;
  color: $white;
  padding: 7px 0;
  border-radius: 20px;
  font-weight: bold;
  min-width: 100px;
}

.YellowCell {
  background-color: $yellow;
  color: $white;
  padding: 7px 0;
  border-radius: 20px;
  font-weight: bold;
  min-width: 100px;
}

.RedCell {
  background-color: $red;
  color: $white;
  padding: 7px 0;
  border-radius: 20px;
  font-weight: bold;
  min-width: 100px;
}

.OrangeCell {
  background-color: $orange;
  color: $white;
  padding: 7px 0;
  border-radius: 20px;
  font-weight: bold;
  min-width: 100px;
}

.GreenCell {
  background-color: $green;
  color: $white;
  padding: 7px 0;
  border-radius: 20px;
  font-weight: bold;
  min-width: 100px;
}

.InactiveCell {
  opacity: 0.5;
}

.MediaGalleryContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  max-height: 400px;
  overflow-y: auto;

  .MediaGalleryNoResultsContainer {
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }

  .MediaGalleryImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 150px;
    height: 150px;
    border: 1px solid $border-color;
    border-radius: 5px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    div {
      display: flex;
      align-content: flex-start;
      justify-content: flex-end;
      gap: 5px;

      margin: 5px 5px 5px 0;

      a {
        background: $secondary_colour;
        padding: 6px 10px;
        border-radius: 5px;
        color: $white;

        &.DeleteMedia {
          background: $red;
        }

        &:hover {
          background: $primary_colour;
          cursor: pointer;
        }
      }
    }
  }
}
